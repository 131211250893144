import { useState, type FC, useEffect } from 'react'
import classNames from 'classnames'

import { useLockBodyScroll, useResponsive } from 'hooks'
import { Link, NavLink } from 'react-router-dom'
import { Menu, Search } from 'components'
import {
  BurgerIcon,
  ArrowRightNavIcon,
  BurgerCloseIcon,
  HeartStrokeIcon,
  ShopCartIcon,
  UserIcon,
  ProfileIcon,

  // TimeFwIcon,
  // FileExportIcon,
  // PaperPlaneIcon,
  PhoneIcon,
  ExiteIcon,

  // WalletIcon,
  // BellRingIcon,
  // LockIcon,
  NewListIcon,
  TelegramIcon,
  ViberIcon,
} from 'assets'

import styles from './Header.module.scss'
import './MobileHeader.scss'
import { ERoutePaths } from 'router/types'
import { useAppSelector } from 'hooks/useTypedSelector'
import MultiLevelMenu from 'components/molecules/MobileMenu'

const Header: FC = () => {
  const userData = localStorage.getItem('userData')
  const [userEmail, setUserEmail] = useState<string>('')
  const isAuthenticated = userData !== null
  const { basketSlice, watchlistSLice } = useAppSelector(state => state)
  const basketQuality = basketSlice.data?.data?.length
  const watchlistQuality = watchlistSLice.data?.length

  const { settingSlice } = useAppSelector(state => state)

  const { isTablet } = useResponsive()

  const [isOpen, setOpen] = useState(false)
  const [isOpenCatalog, setOpenCatalog] = useState(false)

  function extractUsernameFromTelegramAccount(telegramAccount: string) {
    if (!telegramAccount) {
      return null
    }

    let cleanedAccount = telegramAccount.replace(/^https?:\/\/t\.me\//, '')

    cleanedAccount = cleanedAccount.replace(/^@/, '')

    return cleanedAccount
  }

  function generateViberLink(viberInfo: any) {
    if (!viberInfo) {
      return null
    }

    if (viberInfo.startsWith('http') || viberInfo.startsWith('viber')) {
      return (
        <a href={viberInfo} target='_blank'>
          <ViberIcon className='w-7' />
        </a>
      )
    }

    if (/^\+\d{2,3}/.test(viberInfo)) {
      return (
        <a href={`viber://contact?number=${encodeURIComponent(viberInfo)}`} target='_blank'>
          <ViberIcon className='w-7' />
        </a>
      )
    }

    return null
  }

  const openMobileNav = () => {
    setOpen(true)
  }

  const closeMobileNav = () => {
    setOpen(false)
  }

  useEffect(() => {
    const userData = localStorage.getItem('userData')

    if (userData) {
      // const parsedData = JSON.parse(userData)
      setUserEmail('')
    }
  }, [])

  useLockBodyScroll(!isOpen)

  const logout = () => {
    localStorage.removeItem('userData')
    window.open('/', '_self')
  }

  const MobileNavBar = () => (
    <>
      <div className='mobile__header'>
        {!isAuthenticated ? (
          <div className='wrapper'>
            <div className='pages'>
              <Link
                onClick={() => setOpen(false)}
                className='font-normal text-sm flex gap-2 items-center'
                to='/categories'
              >
                {' '}
                <NewListIcon /> Каталог
              </Link>
              <button onClick={() => setOpenCatalog(true)}>catalog</button>
            </div>
          </div>
        ) : (
          <div className='wrapper'>
            <a href='/profile' className='profile__user'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <ProfileIcon />
                <div>
                  <h1 style={{ color: 'black', fontWeight: 600, fontSize: '14px' }}>Мій профіль</h1>
                  <p style={{ color: '#4C5563', fontWeight: 400, fontSize: '14px' }}> {userEmail}</p>
                </div>
              </div>
              <ArrowRightNavIcon />
            </a>

            <div className='pages'>
              <hr style={{ marginTop: 300 }} />
              <div onClick={logout} style={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#959595' }}>
                {' '}
                <ExiteIcon /> Вийти
              </div>

              {/* <hr />

            <Link className='item' to='/'>
              <PhoneIcon fill='#1A1A1A' /> 0 800 32 32 44
            </Link> */}

              <div style={{ display: 'flex', gap: '16px' }}></div>
            </div>
          </div>
        )}
      </div>
    </>
  )

  return (
    <>
      <header className={styles.wrapper}>
        <div className={styles.wrapper__container}>
          <Link to='/'>
            {/* <LogoIcon className={styles.wrapper__logo} /> */}
            <h1
              style={{
                minWidth: 120,
                color: 'white',
                fontWeight: '900',

                fontSize: 26,
                textTransform: 'capitalize',
              }}
            >
              {settingSlice.data.data?.name || 'no name'}
            </h1>
          </Link>

          {!isTablet ? (
            <>
              <Menu />

              <Search />

              <div className={styles.wrapper__icons_group}>
                <NavLink
                  to={ERoutePaths.Saved}
                  className={({ isActive }) =>
                    isActive
                      ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                      : styles.wrapper__icons_group__item
                  }
                >
                  {watchlistQuality !== 0 && watchlistQuality !== undefined && (
                    <div className='list_quality'>{watchlistQuality}</div>
                  )}{' '}
                  <HeartStrokeIcon />
                  <p>Бажане</p>
                </NavLink>

                <NavLink
                  to={ERoutePaths.Basket}
                  className={({ isActive }) =>
                    isActive
                      ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                      : styles.wrapper__icons_group__item
                  }
                >
                  {basketQuality !== 0 && basketQuality !== undefined && (
                    <div className='list_quality'>{basketQuality}</div>
                  )}{' '}
                  <ShopCartIcon />
                  <p>Кошик</p>
                </NavLink>
              </div>
            </>
          ) : (
            <>
              <div className=' flex gap-2 items-center'>
                {settingSlice.data.data?.telegram_url && (
                  <a
                    href={`tg://resolve?domain=${extractUsernameFromTelegramAccount(
                      settingSlice.data.data?.telegram_url
                    )}`}
                    target='__blank'
                  >
                    <TelegramIcon className='w-7' />
                  </a>
                )}
                {generateViberLink(settingSlice.data.data?.viber_url)}
                {isOpen ? (
                  <BurgerCloseIcon className=' ml-2' onClick={closeMobileNav} />
                ) : (
                  <BurgerIcon className='ml-2' onClick={openMobileNav} />
                )}
              </div>
            </>
          )}
          {/* )} */}
        </div>

        {/* {isOpen && <MultiLevelMenu />} */}
      </header>

      <MultiLevelMenu isOpen={isOpen} isAuthenticated={isAuthenticated} userEmail={userEmail} logout={logout} />
    </>
  )
}

export default Header